<template>
    <div class="promotion-content">
        <div class="table-btn">
            <el-button @click="addKeyword" class="btn-red">添加关键词</el-button>
            <el-button @click="delToggle" class="table-top-btn">删除</el-button>
        </div>
        <el-table :data="keyWordList" border style="width: 100%; margin-top: 20px; flex: 1" size="medium"
                  class="jd-table"
                  :header-cell-style="{fontWeight: 'normal', height: '48px', color: '#333333', background: '#F5F5F5'}"
                  @selection-change="deleteKyws"
                  :cell-style="{fontSize: '14px',color: '#333333',fontFamily: 'PingFang SC'}">
            <el-table-column
                type="selection"
                width="50"
                :resizable="false">
            </el-table-column>
            <el-table-column prop="keyword" label="关键词" width="150" :resizable="false"></el-table-column>
            <el-table-column prop="project_name" label="推广计划" width="150" :resizable="false"></el-table-column>
            <el-table-column prop="unit_name" label="推广单元" width="150" :resizable="false"></el-table-column>
            <el-table-column prop="offer" label="出价" width="120" :resizable="false">
                <template slot-scope="scope">
                    <span @click="clickEditPrice(scope.row)" class="plan-name">
                        {{scope.row.offer}}
                        <i class="el-icon-edit edit-plan-iocn"></i>
                    </span>
                    <input type="text"
                           name="priceInput"
                           class="name-input"
                           :ref="`priceInput_${scope.row.id}`"
                           v-model="offer"
                           v-show="scope.row.id === isShow"
                           @blur="validatePrice(scope.row)"
                           oninput="value=value.replace(/[^\d]\./g,'')">
                </template>
            </el-table-column>
            <el-table-column prop="com_index" label="竞争力指数" width="100" :resizable="false"></el-table-column>
            <el-table-column prop="show_num" label="展现数" :resizable="false">
                <template slot-scope="scope">
                    <span>{{scope.row.show_num | showNumber}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="avg_show_rank" label="平均展现排名" width="110" :resizable="false">
                <template slot-scope="scope">
                    <span>{{scope.row.avg_show_rank | showNumber}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="total_cost" label="总费用" :resizable="false">
                <template slot-scope="scope">
                    <span>{{scope.row.total_cost | showNumber}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="total_order_amount" label="总订单金额" width="100" :resizable="false">
                <template slot-scope="scope">
                    <span>{{scope.row.total_order_amount | showNumber}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="click_rate" label="点击率（%）" width="110" :resizable="false">
                <template slot-scope="scope">
                    <span>{{scope.row.click_rate | showNumber}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="tran_rate" label="转化率（%）" width="110" :resizable="false">
                <template slot-scope="scope">
                    <span>{{scope.row.tran_rate | showNumber}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="roi" label="ROI" :resizable="false">
                <template slot-scope="scope">
                    <span>{{scope.row.roi | showNumber}}</span>
                </template>
            </el-table-column>
        </el-table>
        <div style="margin: 10px 0;display: flex;justify-content: flex-end;align-items: center;width: 100%;">
            <el-pagination
                class="operation-pagination"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="listPages.currentPageNum"
                :page-size="listPages.eachPageNum"
                :total="listPages.total"
                :page-sizes="[10]"
                layout=" sizes, prev, pager, next, total, jumper">
            </el-pagination>
        </div>
        <el-drawer
            size="95%"
            title="添加关键词"
            @closed="clearData"
            @open="planKeywords"
            :visible.sync="keywordDrawer">
            <!--使用组件-->
            <!--<addKeywordPage :keyword="keywordDrawer"></addKeywordPage>-->
            <el-scrollbar class="custom-box" :native="false">
                <div class="select-keyword-table">
                    <div class="keyword-select">
                        <el-select v-model="planValue" placeholder="请选择一个计划" style="margin-right: 20px;" @change="selectPlan">
                            <el-option
                                v-for="item in planOptions"
                                :key="item.project_name"
                                :label="item.project_name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                        <el-select v-model="unitValue" placeholder="请选择一个单元" @change="selectUnit">
                            <el-option
                                v-for="item in unitOptions"
                                :key="item.unit_name"
                                :label="item.unit_name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="select-table">
                        <div class="is-selected-tb">
                            <div class="table-btn table-line-group">
                                <span>商品推词</span>
                                <el-input placeholder="输入搜索的关键词" class="search-input" v-model="searchWord" clearable @clear="initGoodsKW" @keyup.enter.native="searchKW">
                                    <el-button slot="append" icon="el-icon-search" @click="searchKW"></el-button>
                                </el-input>
                            </div>
                            <el-table :data="goodsKeyWordList" border style="width: 100%; flex: 1;" height="calc(100% - 50px);" size="medium"
                                      @select="SelectionGoods"
                                      @select-all="SelectionGoods"
                                      :header-cell-style="{fontWeight: 'normal', height: '26px', color: '#333333', background: '#F5F5F5'}"
                                      ref="goodsKywTable"
                                      class="jd-table"
                                      :cell-style="{fontSize: '16px',color: '#333333'}">
                                <el-table-column
                                    type="selection"
                                    :resizable="false">
                                </el-table-column>
                                <el-table-column prop="keyword" label="关键词" :resizable="false"></el-table-column>
                                <el-table-column prop="search_num" label="搜索量" :resizable="false" width="120" align="center"></el-table-column>
                                <el-table-column prop="avg_price" label="平均出价" :resizable="false" width="80" align="center"></el-table-column>
                                <el-table-column prop="click_rate" label="点击率(%)" :resizable="false" width="100" align="center"></el-table-column>
                                <el-table-column prop="tran_rate" label="转化率(%)" :resizable="false" width="100" align="center"></el-table-column>
                                <el-table-column prop="buy_word_heat" label="推荐买词热度" :resizable="false" width="120">
                                    <template slot-scope="scope">
                                        <el-rate
                                            v-model="scope.row.buy_word_heat"
                                            disabled>
                                        </el-rate>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div class="table-page">
                                <el-pagination
                                    layout="prev, pager, next"
                                    :total="kwyPage.total"
                                    :current-page.sync="kwyPage.page"
                                    :page-size="kwyPage.limit"
                                    @current-change="changeKwyPage"
                                ></el-pagination>
                            </div>
                        </div>
                        <div class="selected-tb">
                            <div class="table-btn">
                                <span>已选关键词</span>
                                <el-button @click="delToggleKeyword" class="table-top-btn">删除</el-button>
                            </div>
                            <el-table :data="selectedKeywordList" border style="width: 100%; flex: 1; height: calc(100% - 50px);" size="medium"
                                      :header-cell-style="{fontWeight: 'normal', height: '26px', color: '#333333', background: '#F5F5F5'}"
                                      height="calc(100% - 50px);"
                                      @selection-change="delToggleInsert"
                                      class="jd-table"
                                      :cell-style="{fontSize: '16px',color: '#333333'}">
                                <el-table-column
                                    type="selection"
                                    width="50">
                                </el-table-column>
                                <el-table-column prop="keyword" label="关键词"></el-table-column>
                                <el-table-column label="PC出价">
                                    <template slot-scope="scope">
<!--                                        <el-input  :value="scope.row.pc_price" @focus="clickPrice(scope.row)" :class="[ isShow === scope.row.id ? 'is-hidden': '']"></el-input>-->
                                        <el-input-number style="width: 100%;" :controls="false" v-model.number="scope.row.pc_price" placeholder="请输入内容" ref="offerInput"
                                                  @keyup.enter.native="validateNum(scope.$index,scope.row)" @blur="validateNum(scope.$index,scope.row)"></el-input-number>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="com_index" label="竞争力指数"></el-table-column>
                                <el-table-column prop="bid_position" label="出价排名"></el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
                <div class="box-bottom">
                    <el-button class="btn-red" @click="saveKeyword">确定</el-button>
                </div>
            </el-scrollbar>
        </el-drawer>
    </div>
</template>

<script>
import addKeywordPage from './addKeywordPage'
import _ from "underscore";
import { mapActions,mapGetters } from 'vuex';
import {addKw, editPTD, epKwList, epKwsData, SDepList, stuPlanKeywords, planKeywordList, rankValue,delEPK} from "@/utils/searchPromotion";
import {errMsg, sortByIndex, toFormat2dot, numberReverse} from "@/utils/common";

export default {
    name: "KeywordManagement",
    data() {
        return {
            //是否显示修改输入框
            isShow: null,
            //关键词列表
            keyWordList: [],
            //添加关键词弹窗
            keywordDrawer: false,
            //分页
            listPages: {
                currentPageNum: 1,
                eachPageNum: 10,
                total: 6,
            },
            //s关键词页面
            //计划筛选
            planOptions: [],
            planValue: '',
            //单元筛选
            unitOptions: [],
            unitValue: '',
            //商品推词表格数据
            goodsKeyWordList: [],
            //已选关键词表格数据
            selectedKeywordList: [],
            //手动输入关键词
            manualKeywordInput: '',
            //e关键词页面
            kwyPage: {
                page: 1,
                total: 10,
                limit: 10
            },
            offer: 0,
            price_rank: [],
            deleteCollects: [],
            planId: null,
            kywDeleted: [],
            searchWord: '',
            timeQueue: [],
            planIds: [],
            planKwLists: null,
            goodsId: 0,
            clearLock: true,
        }
    },
    components: {
        addKeywordPage
    },
    computed: {
        ...mapGetters([
            "totalCostValue"
        ]),
    },
    mounted() {
        this.getKeywordManageList();
        this.getPlanNameList();
    },
    methods: {
        ...mapActions([
            'setKeyWordList',
        ]),
        planKeywords() {
            const param = {
                ids: this.planIds.join(",")
            }
            stuPlanKeywords(param).then(res => {
                if (res.code === 200) {
                    this.planKwLists = res.data.data;
                } else {
                    console.log('msg',res.msg)
                    // this.$message.warning(res.msg)
                }
            }).catch(err => {
                console.log("err:", err)
            })
        },
        searchKW() {
            this.getGoodsKeywordList();
        },
        initGoodsKW() {
            this.changeKwyPage(1);
        },
        delToggleInsert(val) {
            this.deleteCollects = val;
        },
        clearData() {
            this.initData();
        },
        initData() {
            this.planId = null;
            this.planValue = '';
            this.unitValue = '';
            this.selectedKeywordList = [];
            this.goodsKeyWordList = [];
            this.kwyPage = {
                page: 1,
                total: 10,
                limit: 10
            }
        },
        clickEditPrice(row) {
            this.isShow = row.id;
            this.offer = Number(row.offer);
            setTimeout(()=> {
                this.$refs[`priceInput_${row.id}`].focus();
            }, 100);
        },
        validatePrice(row) {
            if (Number(row.offer) === Number(this.offer)) {
                this.isShow = null;
                this.offer = null;
                return;
            }
            let data = {
                epk_id: row.id,
                offer: Number(this.offer)
            }
            editPTD(data).then((res) => {
                if (res.code === 200) {
                    this.$message({
                        type: 'success',
                        message: `${res.msg},需要在推广计划列表，重新结束推广，才可以更新关键词列表的数据`,
                        duration: 3000,
                        showClose: true,
                        onClose: () => {
                            this.isShow = null;
                            this.offer = null;
                            this.getKeywordManageList();
                        }
                    });
                } else {
                    this.$message.warning(res.msg);
                }
            }).catch(err => {
                errMsg(err)
            })
        },
        deleteKyws(val) {
            this.kywDeleted = val;
        },
        //获取关键词管理列表
        getKeywordManageList() {
            let param = {
                type: 'page',
                limit: this.listPages.eachPageNum,
                page: this.listPages.currentPageNum
            }
            epKwList( param).then((res) => {
                if (res.code === 200) {
                    this.keyWordList = res.data.data;
                    this.listPages.total = res.data.total;
                    //处理在第二页的数据都做删除时候，还停留在第二页而且显示空的情况
                    if (!res.data.data.length && res.data.total >= this.listPages.eachPageNum) {
                        this.listPages.currentPageNum -= 1;
                        this.getKeywordManageList()
                    }
                } else {
                    this.$message.warning(res.msg)
                }
            }).catch(err => {
                errMsg(err)
            })
        },
        //添加关键词
        addKeyword() {
            let plan_flag = this.totalCostValue
            if (plan_flag.length < 1) {
                return this.$message({
                    type: 'warning',
                    message: '请先添加推广计划',
                    showClose: true
                });
            }
            this.keywordDrawer = true;
        },
        //获取计划名称列表
        getPlanNameList() {
            SDepList().then((res) => {
                if (res.code === 200) {
                    this.planOptions = res.data.list;
                    this.planIds = this.planOptions.map(item => {
                        return item.id
                    })
                } else {
                    this.$message.warning(res.msg)
                }
            }).catch(err => {
                errMsg(err)
            })
        },
        //选择计划名称
        selectPlan(val) {
            // console.log('选择计划id：',val);
            this.planValue = val;
            this.getUnitNameList(this.planValue);
            this.getGoodsKeywordList();
            this.planId = val;
            let currentPlan = _.find(this.planOptions, {id: this.planId});
            // console.log("current", currentPlan)
            this.timeQueue = JSON.parse(currentPlan.time_set);
            this.goodsId = currentPlan.gi_id;
        },
        //选择单元名称
        selectUnit(val) {
            this.unitValue = val;
            this.keyWordsQueue(val);
        },
        async keyWordsQueue(val) {
            const {code, data, msg} = await planKeywordList({id: val});
            if (code === 200) {
                console.log("data", data.list)
                for (let i = 0; i < data.list.length; i++) {
                    let elem = data.list[i]
                    const param = {
                        id: elem.keyword_id,
                        offer: elem.offer,
                        goods_id: this.goodsId,
                        plan_id: val,
                    }
                    const res = await rankValue(param)
                    console.log("res", res)
                    if (res.code === 200) {
                        this.selectedKeywordList.push({
                            id: elem.keyword_id,
                            keyword_id: elem.keyword_id,
                            keyword: elem.keyword,
                            pc_price: Number(elem.offer),
                            com_index: res.data.com_index,
                            bid_position: res.data.bid_position
                        })
                    }
                }
                // this.selectedKeywordList = data.list.map((elem) => {
                //     return {
                //         id: elem.keyword_id,
                //         keyword_id: elem.keyword_id,
                //         keyword: elem.keyword,
                //         pc_price: Number(elem.offer),
                //         com_index: elem.com_index,
                //         bid_position: elem.avg_show_rank
                //     }
                // })
                this.checkGoodKeywordListStatus()
            }

            // let keywordsEd = this.planKwLists[val];
            // console.log("keywordEd", this.planKwLists[val]);
            // // return;
            // let ids = _.map(keywordsEd, (_item) => {
            //     return Number(_item.keyword_id);
            // });
            // if (!ids.length) {
            //     return
            // }
            // ids = ids.join(',');
            // this.getKeyWordsData(ids).then(res => {
            //     console.log("res", res)
            //     let keywordDatas = res.list;
            //     this.selectedKeywordList = _.map(keywordsEd, (item) => {
            //         let tmp_item = _.find(keywordDatas, {keyword_id: item.keyword_id});
            //
            //     });
            //     this.checkGoodKeywordListStatus();
            //
            // }).catch(err => {
            //     errMsg(err)
            // })

        },
        //获取关键词的数据
        getKeyWordsData(ids) {
            let param = {
                nopage: 1,
                id: ids
            };
            return new Promise((resolve, reject) => {
                epKwsData(param).then((res) => {
                    if (res.code === 200) {
                        resolve(res.data);
                    } else {
                        reject(new Error(`${res.msg}`));
                    }
                }).catch(err => {
                    errMsg(err)
                })
            })
        },
        //获取单元名称列表
        getUnitNameList(plan_id) {
            this.unitValue = ""
            for (let key in this.planOptions) {
                const item = this.planOptions[key]
                if (parseInt(item.id) === Number(plan_id)) {
                    this.unitOptions = [{
                        unit_name: item.unit_name,
                        id: item.id
                    }]
                }
            }
        },
        //商品推词的关键词列表gi_id商品id
        getGoodsKeywordList() {
            let param = {
                page: this.kwyPage.page,
                limit: this.kwyPage.limit
            }
            if (this.searchWord.trim().length > 0) {
                param.keyword = this.searchWord;
            }
            epKwsData(param).then( (res) => {
                if (res.code === 200) {
                    this.goodsKeyWordList = _.map(res.data.list, (item) => {
                        if (this.kwyPage.page === 1) {
                            item.buy_word_heat = 5;
                        } else if (this.kwyPage.page > 1 && this.kwyPage.page <= 3) {
                            item.buy_word_heat = 4;
                        } else if (this.kwyPage.page > 3 && this.kwyPage.page <= 6) {
                            item.buy_word_heat = 3;
                        } else if (this.kwyPage.page > 6 && this.kwyPage.page <= 10) {
                            item.buy_word_heat = 2;
                        } else if (this.kwyPage > 10) {
                            item.buy_word_heat = 1;
                        }
                        return item;
                    });
                    this.kwyPage.total = res.data.total;
                    //左侧表格选中的状态
                    if (this.selectedKeywordList.length === 0) {
                        this.$refs.goodsKywTable.clearSelection();
                        return;
                    }
                    this.checkGoodKeywordListStatus();
                } else {
                    this.$message.warning(res.msg);
                }
            }).catch(err => {
                errMsg(err)
            })
        },
        changeKwyPage(val) {
            this.kwyPage.page = val;
            this.getGoodsKeywordList();
        },
        //选择商品关键词
        async SelectionGoods(val) {
            if (val.length === 0) {
                this.selectedKeywordList = [];
            }
            console.log("selectEd",this.selectedKeywordList)
            let selectedIds = [];
            _.each(val, (item) => {
                let tmp = _.find(this.selectedKeywordList, {keyword_id: item.keyword_id});
                if (!tmp) {
                    item.pc_price = 1;
                    rankValue(
                        {id: item.keyword_id, offer: 0, goods_id: this.goodsId, plan_id: this.planValue}
                    ).then(res => {
                        if (res.code === 200) {
                            item.com_index = res.data.com_index;
                            item.bid_position = res.data.bid_position
                            this.selectedKeywordList.push(JSON.parse(JSON.stringify(item)))
                        }
                    }).catch(err => errMsg(err))
                    // this.selectedKeywordList.push(item);
                }
                selectedIds.push(item.keyword_id)
            });

            //过滤掉未选择的关键词
            //判断关键词是不是在左侧的页面里，如果不在的话也不用移除
            const goodsListIds = this.goodsKeyWordList.map((elem) => {
                return elem.keyword_id;
            })
            this.selectedKeywordList.forEach((elem) => {
                if (!goodsListIds.includes(elem.keyword_id)) {
                    selectedIds.push(elem.keyword_id)
                }
            })

            this.selectedKeywordList = this.selectedKeywordList.filter((elem) => {
                return selectedIds.includes(elem.keyword_id)
            })
        },
        checkGoodKeywordListStatus() {
            if (this.selectedKeywordList.length > 0) {
                _.each(this.goodsKeyWordList, (item, index) => {
                    let isSelectItem = _.find(this.selectedKeywordList, { keyword_id: item.keyword_id});
                    if (isSelectItem) {
                        this.$nextTick(() => {
                            this.$refs.goodsKywTable.toggleRowSelection(this.goodsKeyWordList[index], true);
                        });
                    } else {
                        this.$nextTick(() => {
                            this.$refs.goodsKywTable.toggleRowSelection(this.goodsKeyWordList[index], false);
                        });
                    }
                });
            }

        },
        //删除(多条)
        delToggle() {
            // if (this.keyWordList.length === this.kywDeleted.length) {
            //     this.$message.warning("至少保留一个关键词");
            //     return;
            // }
            this.$confirm('确定删除么？删除之后则无法恢复数据', '删除关键词', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(res => {
                this.deleteKeyWords();
            }).catch(err => {
                errMsg(err)
            })

        },
        deleteKeyWords() {
            let ids = [];
            _.each(this.kywDeleted, (item) => {
                ids.push(item.id);
            });

            delEPK({ epk_ids: ids.join(',')}).then((res) => {
                if (res.code === 200) {
                    this.$message({
                        type: 'success',
                        message: res.msg,
                        duration: 1000,
                        onClose: () => {
                            this.getKeywordManageList();
                        }
                    });
                } else {
                    this.$message.warning(res.msg)
                }
            }).catch(err => {
                errMsg(err)
            })
        },
        //修改出价
        clickPrice(row) {
            this.isShow = row.id;
            this.offer = Number(row.pc_price);
            setTimeout(()=> {
                this.$refs.offerInput.focus();
            }, 100);
        },
        //失焦事件
        async validateNum(index, val) {
            const param = {
                id: val.keyword_id,
                offer: Number(val.pc_price),
                goods_id: this.goodsId,
                plan_id: this.planValue
            }
            const {code, data, msg} = await rankValue(param)
            if (code === 200) {
                val.bid_position = data.bid_position;
                val.com_index = data.com_index
            }
        },
        //分页
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            this.listPages.currentPageNum = Number(val);
            this.getKeywordManageList();
        },
        //s关键词页面
        //保存关键词
        saveKeyword() {
            // console.log('已选择', this.selectedKeywordList);
            if (this.selectedKeywordList.length === 0) {
                this.$message.warning('请选择关键词');
                return;
            }
            let data = {
                id: this.planId
            }
            let keywords = _.map(this.selectedKeywordList, (item) => {
                let tmp = {
                    keyword_id: item.keyword_id,
                    keyword: item.keyword,
                    offer: item.pc_price,
                    com_index: item.com_index
                };
                return tmp;
            });
            data.keywords = JSON.stringify(keywords);
            addKw(data).then((res) => {
                if (res.code === 200) {
                    this.$message({
                        type: 'success',
                        message: res.msg,
                        duration: 1000,
                        onClose: () => {
                            this.initData();
                            this.getKeywordManageList();
                        }
                    });
                } else {
                    this.$message.warning(res.msg);
                }
            }).catch(err => {
                errMsg(err)
            })
            this.keywordDrawer = false;

        },
        //删除已选关键词
        delToggleKeyword() {
            _.each(this.deleteCollects, (item, index) => {
                this.selectedKeywordList = _.filter(this.selectedKeywordList, (_item) => {
                    return item.keyword !== _item.keyword;
                });
            });
            _.each(this.goodsKeyWordList, (item, index) => {
                let isItem = _.find(this.deleteCollects, {keyword: item.keyword});
                if (isItem) {
                    this.$refs.goodsKywTable.toggleRowSelection(this.goodsKeyWordList[index], false);
                }
            });

        },
        //保存手动添加的关键词
        addManualKeyword() {

        }
        //e关键词页面
    }
}
</script>

<style scoped lang="scss">
.btn-red:focus, .btn-red:hover {
    color: #fff !important;
    border-color: #fd5658;
    background-color: #fd5658;
}
.promotion-content {
    margin-right: 20px;
    min-height: calc(100vh - 299px);
    .table-btn {
        padding-top: 10px;
    }
    /deep/ .el-table {
        min-height: calc(100vh - 447px);
        .el-table__body {
            tr td {
                padding: 27px 0;
            }
            .cell {
                .plan-name {
                    display: flex;
                    align-items: center;
                    /*justify-content: center;*/
                    cursor: pointer;
                    color: #1E63F1;
                    .edit-plan-iocn {
                        display: none;
                        font-size: 14px;
                        margin-left: 10px;
                    }
                    &:hover {
                        color: #1E63F1;
                        .edit-plan-iocn {
                            display: block;
                            color: #333;
                        }
                    }
                }
                .name-input {
                    display: block;
                    position: absolute;
                    top: 26px;
                    height: 18px;
                    width: 90px;
                }
                .daily-budget {
                    cursor: pointer;
                    color: #1E63F1;
                }
                .del-blue-btn {
                    cursor: pointer;
                    color: #1E63F1;
                }
            }
        }
    }
    .table-top-btn {
        border: 1px solid #DCDFE6;
        color: #333;
    }
}
/deep/ .el-drawer {
    &.rtl {
        &:focus {
            outline: 0;
        }
    }
    .el-drawer__header {
        color: #333333;
        font-size: 24px;
        padding: 40px 20px 0 60px;
        margin-bottom: 0;
        font-weight: 500;
        span[role='heading'] {
            &:focus {
                outline: 0;
            }
        }
    }
    .el-drawer__body {
        height: calc(100% - 74px);
    }
}
.custom-box {
    /deep/ .el-scrollbar__view {
        /*position: relative;*/
        min-height: 100%;
    }
}

//s关键词页面
.custom-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 60px;
    span {
        color: #989898;
        font-size: 18px;
        line-height: 1.5;
    }
    .title {
        color: #333333;
        font-size: 24px;
        padding-bottom: 20px;
        margin-top: 0;
    }
    span:nth-child(4) {
        line-height: 27px;
    }
    .box-bottom {
        /*position: absolute;*/
        /*bottom: 0;*/
        display: block;
        width: 100%;
        margin-top: 30px;
        font-size: 18px;
        color: #333333;
        text-align: right;
        /deep/ .btn-red {
            padding: 17px 45px;
            border-radius: 6px;
        }
    }
    .CustomBudget-radio {
        margin-top: 30px;
        font-size: 18px;
        color: #333333;
    }
    /deep/ .el-radio__input.is-checked .el-radio__inner {
        border-color: #FD4446;
        background: #FD4446;
    }
    /deep/ .el-radio__inner:hover {
        border-color: #FD4446;
    }
    /deep/ .el-radio__input.is-checked+.el-radio__label {
        color: #333;
    }
    .isShowActive {
        background: #FFECC8!important;
        border-color: #ffffff!important;
    }
    .select-keyword-table {
        width: 100%;
        /*height: 100%;*/
        height: calc(100% - 80px);
        .keyword-select {
            margin-bottom: 20px;
        }
        .select-table {
            display: flex;
            justify-content: flex-start;
            width: 100%;
            height: calc(100% - 60px);
            .is-selected-tb {
                height: 100%;
                width: 1%;
                flex: 1;
                border: 1px solid #DFDFDF;
                .table-btn {
                    padding: 10px 20px;
                    span {
                        font-size: 20px;
                        //font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                        font-weight: 500;
                        color: #333333;
                    }
                }
                /deep/ .el-table--medium td, /deep/ .el-table--medium th {
                    padding: 14px 0;
                }
                /deep/ .el-tabs {
                    height: 100%;
                    /deep/ .el-tabs__header {
                        margin: 0;
                        .el-tabs__item {
                            padding-left: 20px;
                            padding-top: 10px;
                            font-weight: 500;
                        }
                    }
                    /deep/ .el-tabs__content {
                        height: calc(100% - 55px);
                        .el-tab-pane {
                            height: 100%;
                        }
                    }
                }
                .manual-input {
                    margin: 20px;
                }
                ul.rule {
                    margin: 0;
                    padding: 0 20px 0 40px;
                    list-style-type: none;
                    li {
                        span {
                            font-size: 16px;
                            //font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                        }
                    }
                }
            }
            .selected-tb {
                width: 40%;
                height: 100%;
                margin-left: 43px;
                border: 1px solid #DFDFDF;
                /deep/ .el-table--medium td, /deep/ .el-table--medium th {
                    padding: 14px 0;
                }
                .table-btn {
                    padding: 10px 20px;
                    span {
                        font-size: 20px;
                        //font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                        font-weight: 500;
                        color: #333333;
                        margin-right: 20px;
                    }
                    /deep/ .el-button {
                        padding: 7px 15px;
                    }
                }
                /deep/ .el-input__inner {
                    height: 30px;
                    line-height: 30px;
                }
            }
        }
    }
}
/deep/ .el-drawer {
    &.rtl {
        &:focus {
            outline: 0;
        }
    }
    .el-drawer__header {
        color: #333333;
        font-size: 24px;
        padding: 40px 20px 0 60px;
        margin-bottom: 0;
        font-weight: 500;
        span[role='heading'] {
            &:focus {
                outline: 0;
            }
        }
    }
    /*.el-drawer__body {*/
    /*height: calc(100% - 74px);*/
    /*}*/
}
.el-tabs {
    /deep/ .el-tabs__header {
        margin: 0 0 32px;
        .el-tabs__active-bar {
            background-color: #FD4446;
        }
        .el-tabs__nav-wrap::after {
            height: 1px;
            background-color: #DFDFDF;
        }
        .el-tabs__item {
            font-size: 20px;
            //font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #333333;
            margin-bottom: 14px;
        }
    }
}
.is-hidden {
    display: none;
}
//e关键词页面
.table-line-group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    span {
        margin-right: 20px;
    }
    .search-input {
        width: 300px;
    }
}

/deep/ .el-rate__icon {
    margin-right: 0;
}
</style>

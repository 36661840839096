<template>
    <div class="operationPromotion-container">
        <el-breadcrumb separator="/" class="student-breadcrumb">
            <el-breadcrumb-item>搜索广告</el-breadcrumb-item>
            <el-breadcrumb-item>推广管理</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="promotion-txt">
            <span>所有推广计划</span>
            <span>推广余额：<b>￥</b>{{amount}}</span>
        </div>
        <div class="promotion-table">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="推广计划" name="first">
                    <PromotionPlan ref="promotion" @upAmount="upateAmount"></PromotionPlan>
                </el-tab-pane>
                <el-tab-pane label="关键词管理-普通计划" name="second">
                    <KeywordManagement ref="keyword"></KeywordManagement>
                </el-tab-pane>
                <el-tab-pane label="人群定向" name="third">
                    <CrowdOrientation ref="crowd"></CrowdOrientation>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import PromotionPlan from '@/components/student/operationPromotion/shopDrainage/PromotionPlan.vue'
import KeywordManagement from '@/components/student/operationPromotion/shopDrainage/KeywordManagement.vue'
import CrowdOrientation from '@/components/student/operationPromotion/shopDrainage/CrowdOrientation.vue'
import { mapActions } from 'vuex';
import {epGetSB} from "@/utils/searchPromotion";

export default {
    name: "SearchAds",
    data() {
        return {
            activeName: 'first',
            amount:null,
        };
    },
    components: {
        PromotionPlan,
        KeywordManagement,
        CrowdOrientation
    },
    mounted() {
        this.getAmout();
        // this.setDataMenusList();
    },
    computed: {
        menus() {
            return this.$store.getters.dataMenuList;
        }
    },
    methods: {
        ...mapActions([
            // 'setDataMenusList',
            'setKeyWordList',
            'setPeopleOrientList',
            'setAmountValue'
        ]),
        upateAmount(val) {
            //更新余额
            this.amount = val;
        },
        handleClick(val) {
            switch (val.name) {
                case "first":
                    // this.setKeyWordList();
                    this.setPeopleOrientList();
                    this.$refs.promotion.getPromotionList();
                    // this.$refs.promotion.getSearchAds();
                    this.$refs.promotion.goodsLists();
                    break;
                case "second":
                    this.$refs.keyword.getKeywordManageList();
                    this.$refs.keyword.getPlanNameList();
                    break;
                case "third":
                    this.$refs.crowd.getCrowdList();
                    this.$refs.crowd.getPlanNameList();
                    break;
            }
        },
        getAmout() {
            epGetSB().then((res) => {
                console.log("res", res)
                if (res.code === 200) {
                    this.amount = res.data.stu_balance;
                    let amount = res.data.balance ? Number(res.data.balance) : 0;
                    this.setAmountValue(amount)
                    // if (Number(this.$lockr.get('competition_type')) === 1) {
                    //     let menus = this.menus;
                    //     if (Number(menus[2].children[4].status) === 0) {
                    //         this.operation_updateStatus(2, 4, 1);
                    //     }
                    // }
                } else {
                    this.$message.warning('获取余额异常');
                }
            }).catch((error) => {
                console.log("error", error)
                this.$message.warning(error.message);
            });
        },

    }
}
</script>

<style scoped lang="scss">
.student-breadcrumb {
    font-size: 14px;
}
.operationPromotion-container {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 120px);
    box-sizing: border-box;
    .title {
        font-size: 20px;
        //font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #333333;
        margin: 20px 0;
        line-height: 1;
    }
    .promotion-table {
        min-height: calc(100vh - 195px);
        width: 100%;
        background: #fff;
        /deep/ .el-tabs {
            min-height: calc(100vh - 215px);
            padding: 20px 0 0 20px;
            .el-tabs__content {
                min-height: calc(100vh - 299px);
                .el-tab-pane {
                    min-height: calc(100vh - 299px);
                }
            }
            /deep/ .el-tabs__header {
                /*margin: 0 0 32px;*/
                .el-tabs__active-bar {
                    background-color: #FD4446;
                }
                .el-tabs__nav-wrap::after {
                    height: 1px;
                    background-color: #DFDFDF;
                }
                .el-tabs__item {
                    font-size: 20px;
                    //font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                    font-weight: 400;
                    color: #333333;
                    /*margin-bottom: 2px;*/
                }
                /deep/.el-tabs__item{
                    line-height: 29px;
                }
                .el-tabs__nav .is-active {
                    color: #FD4446;
                }
            }
        }
    }
}
.promotion-txt{
    display: flex;
    justify-content: space-between;
    color: #333333;
    //font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-size: 20px;
    font-weight: 500;
    line-height: 1;
    margin: 20px 0;
    span:nth-child(2){
        font-size: 18px;
        color: #FD4446;
        font-family: Avenir, Helvetica, Arial, sans-serif;
    }
    b{
        font-size: 12px;
        margin: 0 2px;
        font-weight: 500;
    }
}
</style>
